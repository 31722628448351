.browserdetect {
	width: 100%;
	display: none;
	padding: 40px 20px;
	position: fixed;
	top: 0;
	left: 0;

	background-color: #fff;

	font-family: helvetica, sans-serif;
	color: #333;

	strong {
		font-weight: bold;
		font-size: 120%;
	}
	.no-js &,
	.no-backgroundsize &,
	.no-mq & {
		display: block;
	}

	&.browserdetect--hidden {
		display: none !important;
	}
}

.browserdetect--center {
	text-align: center;
}

.browserdetect__content {
	display: block;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
}

.browserdetect__close {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 10px;
	right: 10px;

	@include font-size(30);
	color: #333;

	&:hover, &:focus {
		color: red;
	}
}

.browserdetect__list {
	display: block;
	margin-top: 40px;
	text-align: center;

	li {
		display: inline-block;
		width: 64px;
		height: 64px;
		margin: 0 20px;
	}

	a {
		display: block;
		width: 100%;
		height: 100%;

		background-repeat: no-repeat;
		background-position: 0 0;

		&.browserdetect__ie      { background-image: url('/images/browserdetect/ie-64x64.png');     }
		&.browserdetect__firefox { background-image: url('/images/browserdetect/ff-64x64.png');     }
		&.browserdetect__chrome  { background-image: url('/images/browserdetect/chrome-64x64.png'); }
		&.browserdetect__safari  { background-image: url('/images/browserdetect/safari-64x64.png'); }
	}
}