html { font-size: 62.5%; }

$font-base:       'gotham_bookregular', sans-serif;
$font-bold:       'gotham_mediumregular', sans-serif;
$font-alt:        'playfair_displayregular', serif;
$font-alt-italic: 'playfair_displayitalic', serif;
$font-icon:       'Arcole';
$image-path:      '/images/';

$color-1:        #4b1e46;
$color-1-darken: #3f2e3d;
$color-2:        #baa264;
$color-3:        #5d5e76;

$white: #fff;
$black: #000;

$color-bg:          #f3f3f3;

$color-text:        #444;
$color-text-light:  $white;

$color-cancel: #FF4242;

$color-link: $color-1;
$color-link-hover: lighten($color-2, 10%);

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$grid-gutter-base:    0 / 2;
$grid-gutter-tablet:  0 / 2;
$grid-gutter-desktop: 0 / 2;
$grid-gutter-wide:    0 / 2;

// $grid-gutter-base:    20px / 2;
// $grid-gutter-tablet:  20px / 2;
// $grid-gutter-desktop: 30px / 2;
// $grid-gutter-wide:    30px / 2;