/***** GOTHAM Book *****/
@font-face {
    font-family: 'gotham_bookregular';
    src: url('../fonts/gotham-book-webfont.eot');
    src: url('../fonts/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham-book-webfont.woff2') format('woff2'),
         url('../fonts/gotham-book-webfont.woff') format('woff'),
         url('../fonts/gotham-book-webfont.ttf') format('truetype'),
         url('../fonts/gotham-book-webfont.svg#gotham_bookregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** GOTHAM Medium *****/
@font-face {
    font-family: 'gotham_mediumregular';
    src: url('../fonts/gotham_medium_regular-webfont.eot');
    src: url('../fonts/gotham_medium_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham_medium_regular-webfont.woff2') format('woff2'),
         url('../fonts/gotham_medium_regular-webfont.woff') format('woff'),
         url('../fonts/gotham_medium_regular-webfont.ttf') format('truetype'),
         url('../fonts/gotham_medium_regular-webfont.svg#gotham_mediumregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** PLAYFAIR DISPLAY Regular *****/
@font-face {
    font-family: 'playfair_displayregular';
    src: url('../fonts/playfairdisplay-regular-webfont.eot');
    src: url('../fonts/playfairdisplay-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/playfairdisplay-regular-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-regular-webfont.woff') format('woff'),
         url('../fonts/playfairdisplay-regular-webfont.ttf') format('truetype'),
         url('../fonts/playfairdisplay-regular-webfont.svg#playfair_displayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** PLAYFAIR DISPLAY Italic *****/
@font-face {
    font-family: 'playfair_displayitalic';
    src: url('../fonts/playfairdisplay-italic-webfont.eot');
    src: url('../fonts/playfairdisplay-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/playfairdisplay-italic-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-italic-webfont.woff') format('woff'),
         url('../fonts/playfairdisplay-italic-webfont.ttf') format('truetype'),
         url('../fonts/playfairdisplay-italic-webfont.svg#playfair_displayitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** FONT ICON *****/
@font-face {
    font-family: 'Arcole';
    src: url('../fonts/Arcole.eot?ovjvtm');
    src: url('../fonts/Arcole.eot?ovjvtm#iefix') format('embedded-opentype'),
         url('../fonts/Arcole.ttf?ovjvtm') format('truetype'),
         url('../fonts/Arcole.woff?ovjvtm') format('woff'),
         url('../fonts/Arcole.svg?ovjvtm#Arcole') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Arcole' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
    content: "\e900";
}
.icon-download:before {
    content: "\e901";
}
.icon-envelope:before {
    content: "\e902";
}
.icon-mobile:before {
    content: "\e903";
}
.icon-phone:before {
    content: "\e904";
}



