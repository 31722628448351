html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
	position: relative;

	@include mq($from: tablet) {

		&:before {
			display: block;
			content: "";
			height: 100%;
			height: calc(100% - 170px);
			width: 1px;
			background-color: rgba($black, 0.1);
			z-index: 1;

			position: absolute;
			top: 0;
			left: 50%;
		}
	}

	@include mq($from: desktop) {

		&:before {
			height: calc(100% - 180px);
		}

		&.home,
		&.valeur {

			&:before {
				height: calc(100% - 160px);
			}
		}
	}

	@include mq($from: wide) {

		&:before {
			height: calc(100% - 220px);
		}

		&.home,
		&.valeur {

			&:before {
				height: calc(100% - 195px);
			}
		}
	}

	@include mq($from: xwide) {

		&:before {
			height: calc(100% - 240px);
		}

		&.home,
		&.valeur {

			&:before {
				height: calc(100% - 215px);
			}
		}
	}
}

body {
	background-color: $color-bg;
	
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;
	
	&.noscroll {
		overflow: hidden;
	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
*/
/***** TEXT *****/
p {
	margin: 0 0 1em 0;
	font-family: $font-base;
	color: $color-text;
	line-height: 1.3em;
}

a {
	color: $color-link;

	transition: color 0.2s ease-in-out;

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-link-hover;
	}
}

/***** TITLES *****/
.title-lev1,
.title-lev2 {
	margin: 0 0 1.4em 0;

	font-family: $font-bold;
	text-transform: uppercase;
	color: $color-1;
	letter-spacing: 0.005em;

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.title-lev1 {
	line-height: 1.2em;
	@include font-size(20);

	@include mq($from: tablet) {
		max-width: 80%;
		@include font-size(28);
	}

	@include mq($from: desktop) {
		max-width: 92%;
		@include font-size(20);
	}

	@include mq($from: wide) {
		max-width: 90%;
		@include font-size(22);
	}

	@include mq($from: xwide) {
		max-width: 90%;
		@include font-size(25);
	}

	&.title-home {
		@include font-size(22);

		@include mq($from: tablet) {
			@include font-size(30);
		}

		@include mq($from: desktop) {
			max-width: 100%;
			@include font-size(24);
		}

		@include mq($from: xwide) {
			@include font-size(26);
		}
	}
}

.title-lev2 {
	
	@include mq($from: tablet) {
		@include font-size(16);
	}

	@include mq($from: desktop) {
		@include font-size(13);
	}

	@include mq($from: wide) {
		@include font-size(15);
	}

	@include mq($from: xwide) {
		@include font-size(16);
	}
}

.subtitle {
	position: relative;
	margin: 0 0 1.4em 0;

	font-family: $font-bold;
	text-transform: uppercase;
	color: $color-2;
	letter-spacing: 0.005em;

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	@include mq($from: tablet) {
		margin: 0 0 1.6em 0;
		@include font-size(15);
	}

	@include mq($from: desktop) {
		margin: 0 0 1.5em 0;
		@include font-size(13);
	}

	@include mq($from: wide) {
		margin: 0 0 1.6em 0;
		@include font-size(14);
	}

	@include mq($from: xwide) {
		margin: 0 0 1.7em 0;
		@include font-size(15);
	}

	&:after {
		display: block;
		content: "";
		width: 60px;
		height: 1px;
		background-color: $color-2;
		position: absolute;
		top: 125%;

		@include mq($from: tablet) {
			top: 130%;
		}

		@include mq($from: xwide) {
			top: 133%;
		}
	}
}

/***** TEXT *****/
p {
	font-family: $font-base;
	@include font-size(14);
	color: $color-text;
	line-height: 1.4em;
	margin: 0 0 1.4em 0;

	@include mq($from: tablet) {
		@include font-size(13);
	}

	@include mq($from: desktop) {
		line-height: 1.3em;
		margin: 0 0 1.3em 0;
	}

	@include mq($from: wide) {
		line-height: 1.4em;
		margin: 0 0 1.4em 0;
	}
}

/***** LINK *****/
.link-download {
	position: relative;
	display: inline-block;
	margin: 2em 0 1em 0;
	padding: 0 0 0 22px;

	font-family: $font-base;
	@include font-size(14);
	color: $color-1;
	color: lighten($color-1, 10%);

	@include mq($from: tablet) {
		@include font-size(13);
	}

	&:before {
		display: inline-block;

		font-family: $font-icon;
		content: "\e901";
		@include font-size(21);

		position: absolute;
		left: 0;
		top: -4px;

		@include mq($from: tablet) {
			@include font-size(19);
			top: -3px;
		}
	}
}

.no-margin {
	margin: 0;
}

/***** LIST ****/
.main-list {
	margin: 0 0 2.2em 0;

	@include mq($from: tablet) {
		margin: 0 0 2.6em 0;
	}

	li {
		margin: 0 0 0.5em 0;

		@include font-size(13);

		&.main-list--title {
			margin: 0 0 1em 0;
			text-transform: uppercase;
			color: $color-2;
		}
	}
}

/***** EDITOR-TEXT *****/
.editor-text {
	color: $color-text;

	p {
		line-height: 1.5;
		margin-bottom: 1.5em;
	}

	a {
		color: $color-link;
		display: inline-block;
		border-bottom: 1px solid $color-link;

		transition:
			color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				color: lighten($color-link, 10%);
				border-color: lighten($color-link, 10%);
			}
		}
	}

	h1 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		text-transform: uppercase;
		@include font-size(20);
		font-weight: 700;
	}

	h2 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
		font-weight: 600;
	}
}

/* 
 ######     #######   ##     ##  ##     ##  ##     ##  ##    ##   
##    ##   ##     ##  ###   ###  ###   ###  ##     ##  ###   ##   
##         ##     ##  #### ####  #### ####  ##     ##  ####  ##   
##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##   
##         ##     ##  ##     ##  ##     ##  ##     ##  ##  ####   
##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###   
 ######     #######   ##     ##  ##     ##   #######   ##    ##   
*/
.section--content {

	@include mq($until: tablet) {
		margin-top: 70px;
	}
}

.wrapper {
	@include clearfix;
	padding: 20px;
	margin: 0 auto;

	@include mq($from: tablet) {
		padding: 0;
		width: 700px;
	}

	@include mq($from: desktop) {
		width: 920px;
	}

	@include mq($from: wide) {
		width: 1080px;
	}

	@include mq($from: xwide) {
		width: 1200px;
	}
}

.wrapper--header {

	@include mq($from: tablet) {
		padding: 20px;
	}

	@include mq($from: desktop) {
		padding: 26px 30px;
	}
}

.wrapper--content {

	@include mq($from: tablet) {
		position: relative;
		margin: 0 auto 60px auto;
	}

	@include mq($from: desktop) {
		margin: 0 auto 100px auto;
	}

	@include mq($from: wide) {
		margin: 0 auto 120px auto;
	}

	@include mq($from: xwide) {
		margin: 0 auto 140px auto;
	}

	
	&:before,
	&:after {

		@include mq($from: desktop) {
			display: block;
			content: "";
			width: 1px;
			background-color: rgba($black, 0.1);
			z-index: 1;

			position: absolute;
			top: 0;
		}

		.home &,
		.valeur & {

			@include mq($from: desktop) {
				height: 100%;
				height: calc(100% + 20px);
			}

			@include mq($from: wide) {
				height: calc(100% + 25px);
			}
		}
	}

	&:before {

		@include mq($from: desktop) {
			left: 25%;
		}

		.societe &,
		.equipe &,
		.expertise &,
		.reference &,
		.contact & {
			@include mq($from: desktop) {
				height: 100%;
			}
		}
	}

	&:after {

		@include mq($from: desktop) {
			right: 25%;
		}
	}
}

.wrapper--footer {

	@include mq($from: tablet) {
		padding: 20px 0;
	}

	@include mq($from: desktop) {
		padding: 30px 0;
	}

	@include mq($from: wide) {
		padding: 40px 0;
	}
}

.clearfix {
	@include clearfix;
}


/* 
 ######    ########   ####  ########   
##    ##   ##     ##   ##   ##     ##  
##         ##     ##   ##   ##     ##  
##   ####  ########    ##   ##     ##  
##    ##   ##   ##     ##   ##     ##  
##    ##   ##    ##    ##   ##     ##  
 ######    ##     ##  ####  ########   
*/
.block {
	@include clearfix;
	position: relative;

	&.block--image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	@include mq($from: tablet) {

		&.block--square {
			padding-top: 100%;
		}

		&.block--double {
			padding-top: 200%;
		}

		&.block--illu-page {

			@include mq($from: tablet) {
				padding-top: 66.666%;
				padding-top: percentage(2/3);
				z-index: 0;

				&.block--illu-page--bg-dark {
					&:after {
						display: block;
						content: "";
						width: 50%;
						height: 100%;

						position: absolute;
						top: 0;
						right: 0;
						z-index: 2;

						background: linear-gradient(to left, rgba($color-1-darken, 0.5), rgba($color-1, 0));
					}
				}
			}

			&:before {

				@include mq($from: tablet) {
					display: block;
					content: "";
					width: 100%;
					height: 100%;

					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
				}
			}

			&.block--image--societe:before {

				@include mq($from: tablet) {
					background: transparent url('#{$image-path}illu-societe.jpg') top center no-repeat;
					background-size: cover;

					@include retina {
						background-image: url('#{$image-path}illu-societe@2x.jpg');
					}
				}
			}

			&.block--image--equipe:before {

				@include mq($from: tablet) {
					background: transparent url('#{$image-path}illu-equipe.jpg') top center no-repeat;
					background-size: cover;

					@include retina {
						background-image: url('#{$image-path}illu-equipe@2x.jpg');
					}
				}
			}

			&.block--image--expertise:before {

				@include mq($from: tablet) {
					background: transparent url('#{$image-path}illu-expertise.jpg') top center no-repeat;
					background-size: cover;

					@include retina {
						background-image: url('#{$image-path}illu-expertise@2x.jpg');
					}
				}
			}

			&.block--image--reference:before {

				@include mq($from: tablet) {
					background: transparent url('#{$image-path}illu-reference.jpg') top center no-repeat;
					background-size: cover;

					@include retina {
						background-image: url('#{$image-path}illu-reference@2x.jpg');
					}
				}
			}

			&.block--image--valeur-top:before {

				@include mq($from: tablet) {
					background: transparent url('#{$image-path}illu-valeur-top.jpg') top center no-repeat;
					background-size: cover;

					@include retina {
						background-image: url('#{$image-path}illu-valeur-top@2x.jpg');
					}
				}
			}

			&.block--image--valeur-bottom:before {

				@include mq($from: tablet) {
					background: transparent url('#{$image-path}illu-valeur-bottom.jpg') top center no-repeat;
					background-size: cover;

					@include retina {
						background-image: url('#{$image-path}illu-valeur-bottom@2x.jpg');
					}
				}
			}

			&.block--image--contact:before {

				@include mq($from: tablet) {
					background: transparent url('#{$image-path}illu-contact.jpg') top center no-repeat;
					background-size: cover;

					@include retina {
						background-image: url('#{$image-path}illu-contact@2x.jpg');
					}
				}
			}
		}

		&.block--illu-page--demi {

			@include mq($from: tablet) {
				padding-top: 100%;
				z-index: 0;
				position: relative;
			}

			@include mq($from: desktop) {
				margin-top: -100%;
			}
		}

		&.block--content-page {

			@include mq($from: desktop) {
				
				&.block--content-page--triple {
					// padding-top: 75%;
					// margin-top: -25%;
				}
			}
		}
	}

	@include mq($from: desktop) {

		&.block--square {
			padding-top: 100%;
		}

		&.block--double {
			padding-top: 66.66666%;
			padding-top: percentage(2/3);

			.col-1-4 & {
				padding-top: 200%;
			}
		}

		&.block--tiers {
			padding-top: 33.3333%;
			padding-top: percentage(1/3);
		}

		&.block--demi {
			padding-top: 50%;
			padding-top: percentage(1/2);
		}
	}
}

.block--inner {

	padding: 20px;

	@include mq($from: tablet) {
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		padding: 40px;
	}

	@include mq($from: desktop) {
		padding: 30px;
	}

	@include mq($from: wide) {
		padding: 40px;
	}

	&.block--inner--content {
		position: relative !important;	

		@include mq($from: tablet) {
			z-index: 10;
			padding-bottom: 60px;
		}

		@include mq($from: desktop) {
			width: 75%;
			left: 25%;
			margin-top: -230px;
		}

		@include mq($from: wide) {
			margin-top: -270px;
		}

		@include mq($from: xwide) {
			margin-top: -300px;
		}
	}

	&.block--inner--color-bg {
		background-color: $color-bg;
	}

	&.block--inner--color-white {
		background-color: $white;
	}

	&.block--inner--color-1 {
		background-color: $color-1;
	}

	&.block--inner--color-3 {
		background-color: $color-3;
	}
}

.block--inner--img {

	@include mq($until: tablet) {
		width: 100%;
		height: auto;	
	}
}

.block--inner--baseline {

	@include mq($from: tablet) {
		width: 50%;
		height: auto;
		z-index: 11;
		background-color: transparent !important;
		
		position: absolute;
		left: auto;
		right: 0;
	}

	@include mq($from: desktop) {
		width: 33.333%;
		width: percentage(1/3);
	}

	p {
		font-family: $font-alt-italic;
		@include font-size(15);
		line-height: 1.3em;
		position: relative;

		@include mq($from: tablet) {
			float: right;
			max-width: 80%;

			@include font-size(18);
			text-align: right;
			line-height: 1.4em;

			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale
		}

		@include mq($from: desktop) {
			max-width: 100%;
			@include font-size(17);
		}

		.block--illu-page--bg-dark & {
			@include mq($from: tablet) {
				color: $color-text-light;
			}
		}

		.block--illu-page--bg-light & {
			@include mq($from: tablet) {
				color: $color-1;
			}
		}

		&:after {

			@include mq($from: tablet) {
				display: block;
				content: "";
				width: 60px;
				height: 1px;

				position: absolute;
				top: 115%;
				right: 0;
			}

			@include mq($from: desktop) {
				width: 40px;
				top: 110%;
			}

			@include mq($from: wide) {
				width: 60px;
				top: 114%;
			}

			.block--illu-page--bg-dark & {
				@include mq($from: tablet) {
					background-color: $white;
				}
			}

			.block--illu-page--bg-light & {
				@include mq($from: tablet) {
					background-color: $color-1;
				}
			}
		}

		.strong {
			font-weight: bold;
		}
	}
}

.page--intro {
	margin: 2em 0;

	font-family: $font-alt-italic;
	@include font-size(16);
	color: $color-3;
	line-height: 1.6em;

	@include mq($from: tablet) {
		max-width: 80%;
		margin: 2.5em 0 3em 0;

		@include font-size(17);
		line-height: 1.7em;
	}

	@include mq($from: desktop) {
		max-width: 80%;
		margin: 2.5em 0;

		@include font-size(16);
		line-height: 1.6em;
	}

	@include mq($from: wide) {
		max-width: 70%;
		margin: 3em 0 3.5em 0;

		@include font-size(17);
		line-height: 1.8em;
	}
}

.page--text {

	@include mq($from: tablet) {
		margin: 0 0 1em 0;
	}

	@include mq($from: wide) {
		margin: 0 0 1.2em 0;

		column-count: 2;
		column-gap: 60px;
	}

	&.page--text--no-column {
		
		@include mq($from: wide) {
			column-count: 1;
		}

		.col-left,
		.col-right {
			
			@include mq($from: wide) {
				width: 50%;
				width: percentage(1/2);
				margin-top: 20px;
			}
		}

		.col-left {

			@include mq($until: wide) {
				margin: 0 0 40px 0;
			}

			@include mq($from: wide) {
				float: left;
				padding: 0 0 20px 0;
			}
		}

		.col-right {
			
			@include mq($from: wide) {
				float: right;
				padding: 0 0 0 20px;
			}
		}
	}
}


/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
.header {
	@include clearfix;
	height: auto;
	position: relative;

	@include mq($from: tablet) {
		background-color: $color-1;
		width: 700px;
		height: 100px;
		margin: 30px auto;
		z-index: 10;
	}

	@include mq($from: desktop) {
		width: 920px;
		height: 120px;
		margin: 40px auto;

		&:before {
			display: block;
			content: "";
			height: 100%;
			width: 1px;
			background-color: rgba($color-bg, 0.2);

			position: absolute;
			top: 0;
			left: 50%;
		}

		&:after {
			display: block;
			content: "";
			height: 40%;
			width: 1px;
			background-color: $color-bg;

			position: absolute;
			top: 30%;
			left: 50%;
		}
	}

	@include mq($from: wide) {
		width: 1080px;
		margin: 40px auto;
	}

	@include mq($from: xwide) {
		width: 1200px;
	}
}

/***** LOGO *****/
.header__logo {
	z-index: 20;

	@include mq($until: tablet) {
		position: fixed;
		width: 100%;
		height: 70px;
		background-color: $color-1;
	}

	@include mq($from: tablet) {
		display: inline-block;
		width: auto;
		height: 100px;
		background-color: transparent;
	}

	@include mq($from: desktop) {
		height: 120px;
	}

	.wrapper--header {

		@include mq($from: tablet) {
			display: inline-block;
			width: auto;
			height: 100%;
			padding: 35px 20px;
		}

		@include mq($from: desktop) {
			padding: 38px 30px;
		}
	}
}

.logo {
	display: block;
	text-indent: -9999px;
	position: relative;
	z-index: 110;

	width: 210px;
	height: 30px;
	background: transparent url('#{$image-path}logo-arcole.png') 0 0 no-repeat;
	background-size: 210px 30px;
	
	@include retina {
		background-image: url('#{$image-path}logo-arcole@2x.png');
		background-size: 210px 30px;
	}

	@include mq($from: tablet) {
		width: 240px;
		height: 34px;
		background-size: 240px 34px;

		@include retina {
			background-image: url('#{$image-path}logo-arcole@2x.png');
			background-size: 240px 34px;
		}
	}

	@include mq($from: desktop) {
		width: 320px;
		height: 46px;
		background-size: 320px 46px;

		@include retina {
			background-image: url('#{$image-path}logo-arcole@2x.png');
			background-size: 320px 46px;
		}
	}

	@include mq($from: wide) {
		width: 350px;
		height: 50px;
		background-size: 350px 50px;

		@include retina {
			background-image: url('#{$image-path}logo-arcole@2x.png');
			background-size: 350px 50px;
		}
	}
}

/***** HEADER__MENU *****/
.header__menu {
	z-index: 10;

	@include mq($until: tablet) {
		display: block;
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100vh;
		width: 100%;
		padding: 10vh 0 20vh 0;
		background-color: rgba($color-bg, 0.9);

		transition: top 0.3s ease-in-out;

		&.open {
			top: 70px;
		}
	}

	@include mq($from: tablet) {
		max-width: 420px;
		float: right;
	}

	@include mq($from: desktop) {
		max-width: 460px;
		float: right;
	}

	@include mq($from: wide) {
		max-width: 540px;
		float: right;
	}

	.wrapper--header {

		@include mq($from: tablet) {
			width: auto;
		}
	}
}

/***** NAV (Commun) *****/
.nav {

	@include mq($until: tablet) {
		text-align: center;
	}

	@include mq($from: tablet) {
		width: auto;
		float: right;
		text-align: right;
	}

	ul li {

		@include mq($until: tablet) {
			height: 8vh;
			line-height: 8vh;
		}

		@include mq($from: tablet) {
			display: inline-block;
		}

		a {
			display: inline-block;
			position: relative;
			
			text-align: center;
			@include font-size(16);
			color: $color-2;

			transition: color 0.2s ease-in-out;
		}
	}
}

/***** NAV ALT *****/
.nav-alt {
	margin-bottom: 3vh;

	@include mq($from: tablet) {
		margin-bottom: 10px;
	}

	@include mq($from: desktop) {
		margin-bottom: 14px;
	}
	
	ul {

		li {
			@include mq($from: tablet) {
				position: relative;
				margin-left: 3px;

				&:before {
					display: block;
					content: "";
					width: 1px;
					height: 16px;
					background-color: $color-2;
					background-color: rgba($color-2, 0.4);

					position: absolute;
					top: 2px;
					left: -4px;
				}

				&:first-of-type {
					margin: 0;

					&:before {
						display: none;
					}
				}
			}

			@include mq($from: desktop) {
				margin-left: 5px;

				&:before {
					left: -5px;
				}
			}

			a {
				padding: 0 20px;
				border: 1px solid transparent;
				line-height: 2em;

				color: $color-2;

				transition: color 0.3s ease-in-out;

				@include mq($from: tablet) {
					padding: 0 4px;
					line-height: 1.8em;

					text-transform: uppercase;
					@include font-size(10);
					color: $color-2;
				}

				&:after {
					content: '';
					position: absolute;
					background: $color-2;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1px;
					transform: scale3d(0, 0.1, 1);

					transition: transform 0.3s;
					transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);
				}

				.no-touch &:hover,
				.no-touchevents &:hover,
				.no-touch &:focus,
				.no-touchevents &:focus,
				.touch &:active,
				.touchevents &:active,
				&.current {
					color: darken($color-2, 10%);

					@include mq($from: tablet) {
						color: lighten($color-2, 10%);
					}

					&:after {
						transform: scale3d(1, 1, 1);

						@include mq($from: tablet) {
							transform: scale3d(0.88, 0.88, 0.88);
						}
					}
				}
			}
		}
	}
}

/***** NAV MAIN *****/
.nav-main {

	ul li {

		@include mq($until: tablet) {
			height: 9vh;
			line-height: 9vh;
		}

		@include mq($from: tablet) {
			margin-left: 1px;
			float: left;

			&:first-of-type {
				margin: 0;
			}
		}

		a {
			padding: 0 15px;
			border: 1px solid transparent;
			line-height: 2.3em;

			font-family: $font-bold;
			text-transform: uppercase;
			color: $color-1;

			transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

			@include mq($from: tablet) {
				padding: 0 10px;
				line-height: 2.6em;

				@include font-size(10);
				text-transform: uppercase;
				color: $color-text-light;
				color: rgba($color-text-light, 0.9);
			}

			@include mq($from: desktop) {
				padding: 0 12px;
				@include font-size(11);
			}

			@include mq($from: wide) {
				padding: 0 15px;
				line-height: 2.8em;
			}


			&:before, &:after {
				content: '';
				position: absolute;
				background-color: $color-1;
				left: 0;
				width: 100%;
				height: 1px;
				transform: scale3d(0, 0.1, 1);

				transition: transform 0.3s, background-color 0.3s;
				transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);

				@include mq($from: tablet) {
					background-color: $white;
					background-color: rgba($white, 0.55);

					transform: scale3d(1, 1, 1);
				}
			}

			&:before {
				top: 0;
			}

			&:after {
				bottom: 0;
			}

			span {
				position: absolute;
				display: block;
				min-height: 100%;
				width: 100%;
				top: 0;
				left: 0;

				&:before, &:after {
					content: '';
					position: absolute;
					background: $color-1;
					
					top: 0;
					width: 1px;
					height: 100%;
					transform: scale3d(0.1, 0, 1);

					transition: transform 0.3s, background-color 0.3s;
					transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);

					@include mq($from: tablet) {
						background-color: $white;
						background-color: rgba($white, 0.6);

						transform: scale3d(1, 1, 1);
					}
				}

				&:before {
					left: 0;
				}

				&:after {
					right: 0;
				}
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active,
			&.current {
				color: $color-1;
				background-color: rgba($white, 0.1);

				@include mq($from: tablet) {
					color: $color-text-light;
				}

				&:before, &:after {
					transform: scale3d(1, 1, 1);

					@include mq($from: tablet) {
						background-color: $white;
					}
				}

				span:before, span:after {
					transform: scale3d(1, 1, 1);

					@include mq($from: tablet) {
						background-color: $white;
					}
				}
			}
		}
	}
}


/* 
########    #######    ######     ######    ##         ########   
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##         ##         ##         ##         
   ##      ##     ##  ##   ####  ##   ####  ##         ######     
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##       #######    ######     ######    ########   ########   
*/
.nav-toggle {
	position: fixed;
	width: 60px;
	height: 64px;
	top: 0px;
	right: 0;
	z-index: 130;

	@include mq($from: tablet) {
		display: none;
	}
}

@mixin line {
	display: inline-block;
	width: 18px;
	height: 3px;
	background: $color-2;
	transition: 0.2s ease-in-out;

	@include mq($from: tablet) {
		width: 20px;
	}
}

.lines-button {
	position: absolute;
	height: auto;
	width: auto;
	right: 20px;
	top: 20px;
	z-index: 101;
	display: inline-block;
	transition: .3s;
	cursor: pointer;
	user-select: none;
	padding: 0;

	.no-touch .nav-toggle:hover &:not(.close) .lines, 
	.no-touch .nav-toggle:hover & .lines:before, 
	.no-touch .nav-toggle:hover & .lines:after {
		background: rgba($color-2, 0.8);
	}

	@include mq($from: tablet) {
		display: none;
	}
}

.lines {
	@include line;
	position: relative;

	&:before, &:after {
		@include line;
		position: absolute;
		left: 0;
		content: '';
		transform-origin: center center;
	}

	&:before { top: 6px; }
	&:after { top: -6px; }
}

.lines-button.close .lines {
	background: transparent;

	&:before, &:after {
		transform-origin: 50% 50%;
		top: 0;
		width: 20px;
	}
	&:before {
		transform: rotate3d(0,0,1, 45deg); 
	}
	&:after {
		transform: rotate3d(0,0,1, -45deg); 
	}
}


/* 
##     ##     ###     ####  ##    ##   
###   ###    ## ##     ##   ###   ##   
#### ####   ##   ##    ##   ####  ##   
## ### ##  ##     ##   ##   ## ## ##   
##     ##  #########   ##   ##  ####   
##     ##  ##     ##   ##   ##   ###   
##     ##  ##     ##  ####  ##    ##   
*/
/***** HOME *****/
.block--image--home-louvre {
	
	&:before {

		@include mq($from: tablet) {
			display: block;
			content: "";
			width: 100%;
			height: 50%;

			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			background: transparent url('#{$image-path}home-louvre.jpg') top center no-repeat;
			background-size: cover;

			@include retina {
				background-image: url('#{$image-path}home-louvre@2x.jpg');
			}
		}

		@include mq($from: desktop) {
			height: 100%;
			z-index: 0;
		}
	}

	.block--inner {
		
		@include mq($from: tablet) {
			height: 50%;
			width: 100%;
			top: 50%;
		}
	}
}

.home--intro-img {
	width: 100%;
	height: auto;
}

.block--inner--intro {

	@include mq(tablet, desktop) {
		background-color: $color-bg !important;
	}

	@include mq($from: desktop) {
		width: percentage(2/3) !important;
		left: auto;
		right: 0;

		z-index: 10;
	}	
}

.home--intro-text {
	font-family: $font-alt-italic;
	@include font-size(14);
	color: $color-text;
	line-height: 1.5em;

	@include mq($from: tablet) {
		@include font-size(16);
		line-height: 1.8em;
	}

	@include mq($from: wide) {
		@include font-size(19)
	}

	@include mq($from: xwide) {
		max-width: 95%;
		@include font-size(20)
	}
}

.block--inner--half {

	@include mq($from: tablet) {
		height: 50%;
	}

	&.block--inner--half-top {

		@include mq($from: tablet) {
			top: 0;
		}
	}

	&.block--inner--half-bottom {
		
		@include mq($from: tablet) {
			top: 50%;
		}
	}
}

/*** .block-link ***/
.block-link {
	display: block;
	height: 100%;
	@include clearfix;

	.block-link__text {
		display: block;
		line-height: 1.6em;
		text-transform: uppercase;
		margin-right: 4px;

		.block--inner--color-1 & {
			color: $color-text-light;
		}

		.block--inner--color-white & {
			color: $color-2;
		}

		@include mq($from: tablet) {
			margin: auto;
			position: relative;
			display: block;
			width: 75%;
			width: calc(75% + 1px);
			height: 75%;
			height: calc(75% + 1px);
			border: 1px solid;
		}

		&:before, &:after {

			@include mq($from: tablet) {
				content: '';
				width: 100%;
				width: calc(100% + 2px);
				height: 1px;

				position: absolute;
				left: -1px;

				transform: scale3d(0, 0.1, 1);

				transition: transform 0.3s;
				transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);

				.block--inner--color-1 & {
					background-color: $white;
				}

				.block--inner--color-white & {
					background-color: $color-2;
				}
			}
		}

		&:before {
			@include mq($from: tablet) {
				top: -1px;
			}
		}

		&:after {
			@include mq($from: tablet) {
				bottom: -1px;
			}
		}

		span.frame-hover {
			position: absolute;
			display: block;
			min-height: 100%;
			width: 100%;
			top: 0;
			left: 0;

			&:before, &:after {
				content: '';
				width: 1px;
				height: 100%;
				height: calc(100% + 2px);

				position: absolute;
				top: -1px;

				transform: scale3d(0.1, 0, 1);

				transition: transform 0.3s;
				transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);

				.block--inner--color-1 & {
					background-color: $white;
				}

				.block--inner--color-white & {
					background-color: $color-2;
				}
			}

			&:before {
				left: -1px;
			}

			&:after {
				right: -1px;
			}
		}

		span.text-content {
				
			@include mq($from: tablet) {
				position: absolute;
				line-height: 1.25em;

				@include font-size(22);
			}

			@include mq($from: desktop) {
				line-height: 1.3em;
				@include font-size(15);
			}

			@include mq($from: wide) {
				@include font-size(17);
			}

			@include mq($from: xwide) {
				@include font-size(20);
			}

			&.text-content--top {

				@include mq($from: tablet) {
					top: 20px;
					left: 20px;
					padding-right: 20px;

					text-align: left;
				}

				@include mq($from: desktop) {
					top: 15px;
					left: 15px;
				}
			}

			&.text-content--bottom {

				@include mq($from: tablet) {
					bottom: 20px;
					right: 20px;
					padding-left: 20px;

					text-align: right;
				}

				@include mq($from: desktop) {
					bottom: 15px;
					right: 15px;
				}
			}
		}
	}

	.block-link__btn {
		display: inline-block;
		border: 1px solid;
		width: 16px;
		height: 16px;

		@include mq($from: tablet) {
			position: relative;
			display: block;
			width: 25%;
			height: 25%;
			margin: 75% 0 0 -1px;
			border: 1px solid;

			float: right;
		}

		&:before {
			display: inline-block;
			width: 100%;
			height: 100%;
			vertical-align: middle;

			@include font-size(11);
			text-align: center;

			transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;

			@include mq($from: tablet) {
				@include font-size(30);
				line-height: 2.15em;
			}

			@include mq($from: desktop) {
				@include font-size(21);
				line-height: 1.88em;
			}

			@include mq($from: wide) {
				@include font-size(22);
				line-height: 2em;
			}

			@include mq($from: xwide) {
				@include font-size(24);
				line-height: 2.2em;
			}
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		cursor: pointer;

		.block-link__text {

			&:before,
			&:after {
				transform: scale3d(1, 1, 1);
			}

			span.frame-hover:before,
			span.frame-hover:after {
				transform: scale3d(1, 1, 1);
			}
		}

		.block-link__btn {
		
			&:before {
				color: $color-1;
				transform: rotate(360deg);
			}
		}

		&.block-link--dark {

			.block-link__btn {
				background-color: $white;

				&:before {
					color: $color-1;
				}
			}
		}

		&.block-link--light {

			.block-link__btn {
				background-color: $color-2;

				&:before {
					color: $white;
				}
			}
		}
	}

	&.block-link--left {

		.block-link__text {

			@include mq($from: tablet) {
				float: left;
			}
		}

		.block-link__btn {

			@include mq($from: tablet) {
				float: right;
				margin: 75% 0 0 -1px;
			}
		}
	}

	&.block-link--right {

		.block-link__text {

			@include mq($from: tablet) {
				float: right;
			}
		}

		.block-link__btn {

			@include mq($from: tablet) {
				float: left;
				margin: 75% -1px 0 0;
			}
		}
	}

	&.block-link--light {
		color: $color-2;
		border-color: $color-2;

		.block-link__text {
			border-color: rgba($color-2, 0.35);
		}

		.block-link__btn {
			border-color: $color-2;
			background-color: rgba($color-2, 0.1);

			&:before {
				color: $color-2;
			}
		}
	}

	&.block-link--dark {
		color: $color-text-light;
		border-color: $white;

		.block-link__text {
			border-color: rgba($white, 0.3);
		}

		.block-link__btn {
			border-color: $white;
			background-color: rgba($white, 0.1);

			&:before {
				color: $white;
			}
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {

			.block-link__btn {
			
				&:before {
					// border-color: $color-2;
				}
			}
		}		
	}
}

.videoWrapper {
position: relative;
padding-bottom: 56.25%; /* 16:9 */
padding-top: 25px;
height: 0;
}
.videoWrapper iframe {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}


.block--image--home-hotel {
	background: transparent url('#{$image-path}home-hotel.jpg') 0 0 no-repeat;
	background-size: cover;

	@include retina {
		background-image: url('#{$image-path}home-hotel@2x.jpg');
		background-size: cover;
	}
}

.col--actu-home {

	&:nth-of-type(2n) {

		.block--inner {
			background-color: $white;
		}
	}
}

.actu-home {

	&.block--inner--color-bg {

		@include mq($until: tablet) {
			border: 1px solid $color-1;
			border-color: rgba($color-1, 0.8);
		}
	}

	.actu--date {
		position: relative;
		margin: 0 0 0.5em 0;

		font-family: $font-alt;
		@include font-size(24);
		color: $color-1;

		@include mq($from: tablet) {
			margin: 0 0 0.6em 0;
			@include font-size(26);
		}

		@include mq($from: desktop) {
			@include font-size(20);
		}

		@include mq($from: wide) {
			@include font-size(25);
		}

		&:after {
			display: block;
			content: "";
			width: 60px;
			height: 1px;
			background-color: $color-1;

			position: absolute;
			top: 110%;
			left: 0;

			@include mq($from: tablet) {
				top: 115%;
			}
		}
	}

	.actu--text {
		margin: 0 0 1em 0;
		color: #606060;

		@include mq($from: desktop) {
			@include font-size(12);
		}

		@include mq($from: wide) {
			@include font-size(13);
		}
	} 

	.actu--link {
		color: lighten($color-1, 12%);

		@include mq($from: desktop) {
			@include font-size(12);
		}

		@include mq($from: wide) {
			@include font-size(13);
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			color: darken($color-2, 10%);
		}
	}
}

/***** SOCIÉTÉ *****/
.block--image--societe {
	background: transparent url('#{$image-path}illu-societe.jpg') 0 0 no-repeat;
}

/***** VALEURS *****/
.row--valeurs {

	@include mq($from: desktop) {
		min-height: 460px;
	}

	@include mq($from: wide) {
		min-height: 540px;
	}

	@include mq($from: xwide) {
		min-height: 600px;
	}
}

.valeur--content {
	
	@include mq($from: desktop) {
		@include clearfix;
		margin-top: -50%;

		width: 100%;
		position: absolute;
		z-index: 0;
	}
}

.block--inner--valeur-item {

	@include mq($from: desktop) {
		padding: 20px 25px;
	}

	@include mq($from: wide) {
		padding: 30px;
	}

	&.block--inner--color-bg {

		@include mq($until: tablet) {
			border: 1px solid $color-1;
			border-color: rgba($color-1, 0.8);
		}
	}

	&.block--inner--color-1,
	&.block--inner--color-3 {
		
		.title-lev2 {
			color: $color-text-light;
		}
	}

	.title-lev2 {
		max-width: 80%;

		@include mq($from: tablet) {
			max-width: 75%;
		}

		@include mq($from: desktop) {
			max-width: 95%;
		}

		@include mq($from: wide) {
			max-width: 85%;
		}
	}
}

.valeur--number {
	position: relative;
	margin: 0 0 0.4em 0;

	font-family: $font-bold;
	@include font-size(28);
	color: $color-1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include mq($from: tablet) {
		margin: 0 0 0.45em 0;
		@include font-size(30);
	}

	@include mq($from: desktop) {
		@include font-size(20);
	}

	@include mq($from: wide) {
		@include font-size(26);
	}

	@include mq($from: xwide) {
		@include font-size(28);
	}

	.block--inner--color-1 &,
	.block--inner--color-3 & {
		color: $color-text-light;
	}

	&:after {
		display: block;
		content: "";
		width: 60px;
		height: 1px;
		background-color: $color-1;

		position: absolute;
		top: 110%;
		left: 0;

		@include mq($from: tablet) {
			top: 115%;
		}

		.block--inner--color-1 &,
		.block--inner--color-3 & {
			background-color: $white;
		}
	}
}

.valeur--text {

	@include mq($from: desktop) {
		@include font-size(12);
		line-height: 1.1em;
	}

	@include mq($from: wide) {
		@include font-size(13);
		line-height: 1.2em;
	}

	@include mq($from: xwide) {
		line-height: 1.4em;
	}

	.block--inner--color-1 &,
	.block--inner--color-3 & {
		color: $color-text-light;
		color: rgba($color-text-light, 0.75);
	}
}

/***** CONTACT *****/
.contact-icon--container {
	line-height: 1.6em;
}

.contact-icon {
	display: inline-block;
	margin: 0 6px 0 0;

	&:before {
		display: block;
		line-height: 2em;
		
		@include font-size(11);
		color: $color-text;
	}
}

.contact-cdaf {
	display: inline-block;

	position: relative;
	padding: 0 0 0 78px;
	margin: 1.6em 0 1.4em 0;

	@include font-size(13);
	line-height: 1.35em;

	@include mq($from: desktop) {
		margin-top: 2.4em;
	}

	&:before {
		display: block;
		content: "";
		width: 68px;
		height: 29px;

		position: absolute;
		top: 2px;
		left: 0;

		background: transparent url('#{$image-path}cdaf2.png') 0 0 no-repeat;
		background-size: 68px 58px;

		transition: background-position 0.2s ease-in-out;

		@include retina {
			background-image: url('#{$image-path}cdaf2@2x.png');
			background-size: 68px 58px;
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		cursor: pointer;

		&:before {
			background-position: 0 -30px;
		}
	}
}

.contact-form {

	li:not(:last-of-type){
		position: relative;
		margin: 0 0 8px 0;
		padding: 15px 10px;
		background-color: $color-bg;
		border: 2px solid $color-bg;

		color: $color-1;

		transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

		@include mq($from: tablet) {
			width: 350px;
		}

		@include mq($from: wide) {
			width: 100%;
		}

		&.input-active{
			background-color: transparent;

			label {
				@include font-size(9);
				top: 5px;
				color: darken($color-2, 15%);
			}

			border-color: darken($color-2, 5%);
		}

		&.error {
			border-color: $color-cancel;
		}
	}

	input,
	textarea {
		@include input_mobile;
		width: 100%;
		border: none;
		background-color: transparent;
	}

	textarea {
		height: 120px;
	}

	label{
		display: block;
		padding: 0 10px;
		line-height: 1em;

		position: absolute;
		top: 18px;
		left: 0;
		right: 0;

		@include font-size(13);

		transition: top 0.2s ease-in-out, color 0.2s ease-in-out, font-size 0.2s ease-in-out;
	}

	button.submit {
		@include input_mobile;
		position: relative;

		height: 50px;
		line-height: 51px;
		width: 100%;

		padding: 0 40px;
		border: none;
		background-color: $color-1;
		vertical-align: middle;
		z-index: 1;
		overflow: hidden;

		font-family: $font-base;
		color: $color-text-light;
		text-transform: uppercase;
		letter-spacing: 0.05em;

		transition: background-color 0.3s;
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

		@include mq($from: tablet) {
			width: auto;
			margin: 10px 0 0 0;
		}

		&:after, 
		span {
			display: block;
			width: 100%;
			height: 100%;
			transition: transform 0.3s, opacity 0.3s;
			transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		}

		&:after {
			padding: 0 20px;
			content: attr(data-text);
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transform: translate3d(0, 40%, 0);
		}

		span {
			vertical-align: middle;
		}

		.no-touch & {
			&:hover, &:focus {
				background-color: $color-2;

				&:after {
					opacity: 1;
					transform: translate3d(0, 0, 0);
				}

				span {
					opacity: 0;
					transform: translate3d(0, -25%, 0);
				}
			}
		}
	}
}


/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/
.footer {
	position: relative;
	z-index: 10;
	background-color: $color-1-darken;

	@include mq($from: tablet) {
		height: 110px;
	}

	@include mq($from: desktop) {
		height: 80px;
	}

	@include mq($from: wide) {
		height: 100px;
	}

	p, a {
		line-height: 1.4em;

		font-family: $font-base;
		@include font-size(12);
		color: $color-text-light;
		color: rgba($color-text-light, 0.75);

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@include mq($from: tablet) {
			margin: 0;
		}

		&.footer--left {
			
			@include mq($from: tablet) {
				width: auto;
				float: left;
			}
		}

		&.footer--right {
			color: rgba($color-text-light, 0.55);

			@include mq($from: tablet) {
				width: auto;
				float: right;
			}
		}
	}

	span.footer-strong {
		font-family: $font-bold;
		text-transform: uppercase;
	}

	a {
		transition: color 0.2s ease-in-out;

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			color: $color-text-light;
		}
	}
}