@charset "UTF-8";
/**	
 * IMPORTS 
 */
/* RESET */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

html {
  margin: 0;
  padding: 0;
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0; }

body, button, input, select, textarea {
  font-family: sans-serif;
  color: #222; }

::-moz-selection {
  background: #ccc;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #ccc;
  color: #fff;
  text-shadow: none; }

a {
  text-decoration: none;
  outline: none; }

a:focus {
  outline: none; }

a:hover, a:active {
  outline: none; }

p {
  margin: 0 0 1em 0; }

p:last-of-type {
  margin: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

pre, code, kbd, samp {
  font-family: monospace, monospace;
  _font-family: 'courier new', monospace; }

code {
  display: inline-block; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: "";
  content: none; }

small {
  font-size: 85%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

strong {
  font-weight: normal; }

ul, ol {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0 0 0 0;
  font-size: 100%; }

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

dd {
  margin: 0; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal;
  *overflow: visible; }

table button, table input {
  *overflow: auto; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid, textarea:invalid {
  background-color: #f0dddd; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

html {
  font-size: 62.5%; }

@media screen and (min-width: 320px) {
  head {
    font-family: "mobile 320px"; }
  body:after {
    content: "mobile - min-width: 320px"; } }

@media screen and (min-width: 480px) {
  head {
    font-family: "mobileLandscape 480px"; }
  body:after {
    content: "mobileLandscape - min-width: 480px"; } }

@media screen and (min-width: 768px) {
  head {
    font-family: "tablet 768px"; }
  body:after {
    content: "tablet - min-width: 768px"; } }

@media screen and (min-width: 1024px) {
  head {
    font-family: "desktop 1024px"; }
  body:after {
    content: "desktop - min-width: 1024px"; } }

@media screen and (min-width: 1200px) {
  head {
    font-family: "wide 1200px"; }
  body:after {
    content: "wide - min-width: 1200px"; } }

@media screen and (min-width: 1400px) {
  head {
    font-family: "xwide 1400px"; }
  body:after {
    content: "xwide - min-width: 1400px"; } }

head {
  clear: both; }
  head title {
    font-family: "mobile 320px, mobileLandscape 480px, tablet 768px, desktop 1024px, wide 1200px, xwide 1400px"; }

body:after {
  display: none; }

*[data-usn-if] {
  display: none; }

.browserdetect {
  width: 100%;
  display: none;
  padding: 40px 20px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  font-family: helvetica, sans-serif;
  color: #333; }
  .browserdetect strong {
    font-weight: bold;
    font-size: 120%; }
  .no-js .browserdetect,
  .no-backgroundsize .browserdetect,
  .no-mq .browserdetect {
    display: block; }
  .browserdetect.browserdetect--hidden {
    display: none !important; }

.browserdetect--center {
  text-align: center; }

.browserdetect__content {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto; }

.browserdetect__close {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  font-size: 3rem;
  color: #333; }
  .browserdetect__close:hover, .browserdetect__close:focus {
    color: red; }

.browserdetect__list {
  display: block;
  margin-top: 40px;
  text-align: center; }
  .browserdetect__list li {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 0 20px; }
  .browserdetect__list a {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0 0; }
    .browserdetect__list a.browserdetect__ie {
      background-image: url("/images/browserdetect/ie-64x64.png"); }
    .browserdetect__list a.browserdetect__firefox {
      background-image: url("/images/browserdetect/ff-64x64.png"); }
    .browserdetect__list a.browserdetect__chrome {
      background-image: url("/images/browserdetect/chrome-64x64.png"); }
    .browserdetect__list a.browserdetect__safari {
      background-image: url("/images/browserdetect/safari-64x64.png"); }

/***** GOTHAM Book *****/
@font-face {
  font-family: 'gotham_bookregular';
  src: url("../fonts/gotham-book-webfont.eot");
  src: url("../fonts/gotham-book-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-book-webfont.woff2") format("woff2"), url("../fonts/gotham-book-webfont.woff") format("woff"), url("../fonts/gotham-book-webfont.ttf") format("truetype"), url("../fonts/gotham-book-webfont.svg#gotham_bookregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/***** GOTHAM Medium *****/
@font-face {
  font-family: 'gotham_mediumregular';
  src: url("../fonts/gotham_medium_regular-webfont.eot");
  src: url("../fonts/gotham_medium_regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham_medium_regular-webfont.woff2") format("woff2"), url("../fonts/gotham_medium_regular-webfont.woff") format("woff"), url("../fonts/gotham_medium_regular-webfont.ttf") format("truetype"), url("../fonts/gotham_medium_regular-webfont.svg#gotham_mediumregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/***** PLAYFAIR DISPLAY Regular *****/
@font-face {
  font-family: 'playfair_displayregular';
  src: url("../fonts/playfairdisplay-regular-webfont.eot");
  src: url("../fonts/playfairdisplay-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/playfairdisplay-regular-webfont.woff2") format("woff2"), url("../fonts/playfairdisplay-regular-webfont.woff") format("woff"), url("../fonts/playfairdisplay-regular-webfont.ttf") format("truetype"), url("../fonts/playfairdisplay-regular-webfont.svg#playfair_displayregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/***** PLAYFAIR DISPLAY Italic *****/
@font-face {
  font-family: 'playfair_displayitalic';
  src: url("../fonts/playfairdisplay-italic-webfont.eot");
  src: url("../fonts/playfairdisplay-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/playfairdisplay-italic-webfont.woff2") format("woff2"), url("../fonts/playfairdisplay-italic-webfont.woff") format("woff"), url("../fonts/playfairdisplay-italic-webfont.ttf") format("truetype"), url("../fonts/playfairdisplay-italic-webfont.svg#playfair_displayitalic") format("svg");
  font-weight: normal;
  font-style: normal; }

/***** FONT ICON *****/
@font-face {
  font-family: 'Arcole';
  src: url("../fonts/Arcole.eot?ovjvtm");
  src: url("../fonts/Arcole.eot?ovjvtm#iefix") format("embedded-opentype"), url("../fonts/Arcole.ttf?ovjvtm") format("truetype"), url("../fonts/Arcole.woff?ovjvtm") format("woff"), url("../fonts/Arcole.svg?ovjvtm#Arcole") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Arcole' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-add:before {
  content: "\e900"; }

.icon-download:before {
  content: "\e901"; }

.icon-envelope:before {
  content: "\e902"; }

.icon-mobile:before {
  content: "\e903"; }

.icon-phone:before {
  content: "\e904"; }

.hidden {
  display: none !important; }

.clear {
  clear: both; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.absolute-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.vertical-align {
  display: table;
  width: 100%;
  height: 100%; }

.vertical-align-inner {
  display: table-cell;
  vertical-align: middle; }

.to-tablet,
.until-tablet {
  display: none; }

.to-desktop,
.until-desktop {
  display: none; }

.from-wide {
  display: none; }

.from-xwide {
  display: none; }

#__bs_notify__ {
  width: 170px !important;
  right: 50% !important;
  padding: 10px !important;
  margin-right: -85px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 0 !important;
  font-size: 12px !important; }

/* 
 ######    ########   ####  ########   
##    ##   ##     ##   ##   ##     ##  
##         ##     ##   ##   ##     ##  
##   ####  ########    ##   ##     ##  
##    ##   ##   ##     ##   ##     ##  
##    ##   ##    ##    ##   ##     ##  
 ######    ##     ##  ####  ########   
 */
.row {
  display: block;
  *zoom: 1;
  margin: 0;
  margin: 0;
  margin: 0;
  position: relative; }
  .row:before, .row:after {
    display: table;
    content: ""; }
  .row:after {
    clear: both; }

.col-1-2,
.col-2-2,
.col-1-3,
.col-2-3,
.col-3-3,
.col-1-4,
.col-2-4,
.col-3-4,
.col-4-4 {
  display: block;
  width: 100%;
  padding: 0;
  float: left;
  padding: 0;
  padding: 0; }

.col-1-2 {
  width: 50%; }

.col-1-2--alt {
  float: right; }

.col-1-3 {
  width: 33.33333%; }

.col-2-3 {
  width: 66.66667%; }

.col-1-4 {
  width: 50%;
  width: 25%; }

.col-2-4 {
  width: 50%; }

.col-3-4 {
  width: 50%;
  width: 75%; }

.col-3-4--alt {
  *zoom: 1;
  width: 100%;
  width: 75%; }
  .col-3-4--alt:before, .col-3-4--alt:after {
    display: table;
    content: ""; }
  .col-3-4--alt:after {
    clear: both; }

.col-4-4 {
  width: 100%; }

.col-sup {
  position: relative;
  z-index: 1; }

.col-inf {
  position: relative;
  z-index: 0; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit;
  outline: none; }

a, a:hover, a:focus, a:active, a:visited {
  outline: none; }

html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  position: relative; }
  html:before {
    display: block;
    content: "";
    height: 100%;
    height: calc(100% - 170px);
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%; }
  html:before {
    height: calc(100% - 180px); }
  html.home:before, html.valeur:before {
    height: calc(100% - 160px); }

body {
  background-color: #f3f3f3;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "gotham_bookregular", sans-serif;
  color: #444; }
  body.noscroll {
    overflow: hidden; }

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
*/
/***** TEXT *****/
p {
  margin: 0 0 1em 0;
  font-family: "gotham_bookregular", sans-serif;
  color: #444;
  line-height: 1.3em; }

a {
  color: #4b1e46;
  transition: color 0.2s ease-in-out; }
  .no-touch a:hover,
  .no-touchevents a:hover,
  .no-touch a:focus,
  .no-touchevents a:focus,
  .touch a:active,
  .touchevents a:active {
    color: #cab787; }

/***** TITLES *****/
.title-lev1,
.title-lev2 {
  margin: 0 0 1.4em 0;
  font-family: "gotham_mediumregular", sans-serif;
  text-transform: uppercase;
  color: #4b1e46;
  letter-spacing: 0.005em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.title-lev1 {
  line-height: 1.2em;
  font-size: 20px;
  font-size: 2rem;
  max-width: 80%;
  font-size: 28px;
  font-size: 2.8rem;
  max-width: 92%;
  font-size: 20px;
  font-size: 2rem; }
  .title-lev1.title-home {
    font-size: 22px;
    font-size: 2.2rem;
    font-size: 30px;
    font-size: 3rem;
    max-width: 100%;
    font-size: 24px;
    font-size: 2.4rem; }

.title-lev2 {
  font-size: 16px;
  font-size: 1.6rem;
  font-size: 13px;
  font-size: 1.3rem; }

.subtitle {
  position: relative;
  margin: 0 0 1.4em 0;
  font-family: "gotham_mediumregular", sans-serif;
  text-transform: uppercase;
  color: #baa264;
  letter-spacing: 0.005em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 1.6em 0;
  font-size: 15px;
  font-size: 1.5rem;
  margin: 0 0 1.5em 0;
  font-size: 13px;
  font-size: 1.3rem; }
  .subtitle:after {
    display: block;
    content: "";
    width: 60px;
    height: 1px;
    background-color: #baa264;
    position: absolute;
    top: 125%;
    top: 130%; }

/***** TEXT *****/
p {
  font-family: "gotham_bookregular", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #444;
  line-height: 1.4em;
  margin: 0 0 1.4em 0;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.3em;
  margin: 0 0 1.3em 0; }

/***** LINK *****/
.link-download {
  position: relative;
  display: inline-block;
  margin: 2em 0 1em 0;
  padding: 0 0 0 22px;
  font-family: "gotham_bookregular", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #4b1e46;
  color: #6f2d68;
  font-size: 13px;
  font-size: 1.3rem; }
  .link-download:before {
    display: inline-block;
    font-family: "Arcole";
    content: "\e901";
    font-size: 21px;
    font-size: 2.1rem;
    position: absolute;
    left: 0;
    top: -4px;
    font-size: 19px;
    font-size: 1.9rem;
    top: -3px; }

.no-margin {
  margin: 0; }

/***** LIST ****/
.main-list {
  margin: 0 0 2.2em 0;
  margin: 0 0 2.6em 0; }
  .main-list li {
    margin: 0 0 0.5em 0;
    font-size: 13px;
    font-size: 1.3rem; }
    .main-list li.main-list--title {
      margin: 0 0 1em 0;
      text-transform: uppercase;
      color: #baa264; }

/***** EDITOR-TEXT *****/
.editor-text {
  color: #444; }
  .editor-text p {
    line-height: 1.5;
    margin-bottom: 1.5em; }
  .editor-text a {
    color: #4b1e46;
    display: inline-block;
    border-bottom: 1px solid #4b1e46;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out; }
    .no-touch .editor-text a:hover, .no-touch .editor-text a:focus {
      color: #6f2d68;
      border-color: #6f2d68; }
  .editor-text h1 {
    margin-bottom: 1.5em;
    font-family: "playfair_displayregular", serif;
    text-transform: uppercase;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 700; }
  .editor-text h2 {
    margin-bottom: 1.5em;
    font-family: "playfair_displayregular", serif;
    font-size: 16px;
    font-size: 1.6rem; }
  .editor-text h3 {
    margin-bottom: 1em;
    font-weight: 600; }

/* 
 ######     #######   ##     ##  ##     ##  ##     ##  ##    ##   
##    ##   ##     ##  ###   ###  ###   ###  ##     ##  ###   ##   
##         ##     ##  #### ####  #### ####  ##     ##  ####  ##   
##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##   
##         ##     ##  ##     ##  ##     ##  ##     ##  ##  ####   
##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###   
 ######     #######   ##     ##  ##     ##   #######   ##    ##   
*/
.wrapper {
  *zoom: 1;
  padding: 20px;
  margin: 0 auto;
  padding: 0;
  width: 700px;
  width: 920px; }
  .wrapper:before, .wrapper:after {
    display: table;
    content: ""; }
  .wrapper:after {
    clear: both; }

.wrapper--header {
  padding: 20px;
  padding: 26px 30px; }

.wrapper--content {
  position: relative;
  margin: 0 auto 60px auto;
  margin: 0 auto 100px auto; }
  .wrapper--content:before, .wrapper--content:after {
    display: block;
    content: "";
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: absolute;
    top: 0; }
    .home .wrapper--content:before,
    .valeur .wrapper--content:before, .home .wrapper--content:after,
    .valeur .wrapper--content:after {
      height: 100%;
      height: calc(100% + 20px); }
  .wrapper--content:before {
    left: 25%; }
    .societe .wrapper--content:before,
    .equipe .wrapper--content:before,
    .expertise .wrapper--content:before,
    .reference .wrapper--content:before,
    .contact .wrapper--content:before {
      height: 100%; }
  .wrapper--content:after {
    right: 25%; }

.wrapper--footer {
  padding: 20px 0;
  padding: 30px 0; }

.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    display: table;
    content: ""; }
  .clearfix:after {
    clear: both; }

/* 
 ######    ########   ####  ########   
##    ##   ##     ##   ##   ##     ##  
##         ##     ##   ##   ##     ##  
##   ####  ########    ##   ##     ##  
##    ##   ##   ##     ##   ##     ##  
##    ##   ##    ##    ##   ##     ##  
 ######    ##     ##  ####  ########   
*/
.block {
  *zoom: 1;
  position: relative; }
  .block:before, .block:after {
    display: table;
    content: ""; }
  .block:after {
    clear: both; }
  .block.block--image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
  .block.block--square {
    padding-top: 100%; }
  .block.block--double {
    padding-top: 200%; }
  .block.block--illu-page {
    padding-top: 66.666%;
    padding-top: 66.66667%;
    z-index: 0; }
    .block.block--illu-page.block--illu-page--bg-dark:after {
      display: block;
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      background: linear-gradient(to left, rgba(63, 46, 61, 0.5), rgba(75, 30, 70, 0)); }
    .block.block--illu-page:before {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    .block.block--illu-page.block--image--societe:before {
      background: transparent url("/images/illu-societe.jpg") top center no-repeat;
      background-size: cover; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
        .block.block--illu-page.block--image--societe:before {
          background-image: url("/images/illu-societe@2x.jpg"); } }
    .block.block--illu-page.block--image--equipe:before {
      background: transparent url("/images/illu-equipe.jpg") top center no-repeat;
      background-size: cover; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
        .block.block--illu-page.block--image--equipe:before {
          background-image: url("/images/illu-equipe@2x.jpg"); } }
    .block.block--illu-page.block--image--expertise:before {
      background: transparent url("/images/illu-expertise.jpg") top center no-repeat;
      background-size: cover; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
        .block.block--illu-page.block--image--expertise:before {
          background-image: url("/images/illu-expertise@2x.jpg"); } }
    .block.block--illu-page.block--image--reference:before {
      background: transparent url("/images/illu-reference.jpg") top center no-repeat;
      background-size: cover; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
        .block.block--illu-page.block--image--reference:before {
          background-image: url("/images/illu-reference@2x.jpg"); } }
    .block.block--illu-page.block--image--valeur-top:before {
      background: transparent url("/images/illu-valeur-top.jpg") top center no-repeat;
      background-size: cover; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
        .block.block--illu-page.block--image--valeur-top:before {
          background-image: url("/images/illu-valeur-top@2x.jpg"); } }
    .block.block--illu-page.block--image--valeur-bottom:before {
      background: transparent url("/images/illu-valeur-bottom.jpg") top center no-repeat;
      background-size: cover; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
        .block.block--illu-page.block--image--valeur-bottom:before {
          background-image: url("/images/illu-valeur-bottom@2x.jpg"); } }
    .block.block--illu-page.block--image--contact:before {
      background: transparent url("/images/illu-contact.jpg") top center no-repeat;
      background-size: cover; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
        .block.block--illu-page.block--image--contact:before {
          background-image: url("/images/illu-contact@2x.jpg"); } }
  .block.block--illu-page--demi {
    padding-top: 100%;
    z-index: 0;
    position: relative;
    margin-top: -100%; }
  .block.block--square {
    padding-top: 100%; }
  .block.block--double {
    padding-top: 66.66666%;
    padding-top: 66.66667%; }
    .col-1-4 .block.block--double {
      padding-top: 200%; }
  .block.block--tiers {
    padding-top: 33.3333%;
    padding-top: 33.33333%; }
  .block.block--demi {
    padding-top: 50%;
    padding-top: 50%; }

.block--inner {
  padding: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 40px;
  padding: 30px; }
  .block--inner.block--inner--content {
    position: relative !important;
    z-index: 10;
    padding-bottom: 60px;
    width: 75%;
    left: 25%;
    margin-top: -230px; }
  .block--inner.block--inner--color-bg {
    background-color: #f3f3f3; }
  .block--inner.block--inner--color-white {
    background-color: #fff; }
  .block--inner.block--inner--color-1 {
    background-color: #4b1e46; }
  .block--inner.block--inner--color-3 {
    background-color: #5d5e76; }

.block--inner--baseline {
  width: 50%;
  height: auto;
  z-index: 11;
  background-color: transparent !important;
  position: absolute;
  left: auto;
  right: 0;
  width: 33.333%;
  width: 33.33333%; }
  .block--inner--baseline p {
    font-family: "playfair_displayitalic", serif;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.3em;
    position: relative;
    float: right;
    max-width: 80%;
    font-size: 18px;
    font-size: 1.8rem;
    text-align: right;
    line-height: 1.4em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100%;
    font-size: 17px;
    font-size: 1.7rem; }
    .block--illu-page--bg-dark .block--inner--baseline p {
      color: #fff; }
    .block--illu-page--bg-light .block--inner--baseline p {
      color: #4b1e46; }
    .block--inner--baseline p:after {
      display: block;
      content: "";
      width: 60px;
      height: 1px;
      position: absolute;
      top: 115%;
      right: 0;
      width: 40px;
      top: 110%; }
      .block--illu-page--bg-dark .block--inner--baseline p:after {
        background-color: #fff; }
      .block--illu-page--bg-light .block--inner--baseline p:after {
        background-color: #4b1e46; }
    .block--inner--baseline p .strong {
      font-weight: bold; }

.page--intro {
  margin: 2em 0;
  font-family: "playfair_displayitalic", serif;
  font-size: 16px;
  font-size: 1.6rem;
  color: #5d5e76;
  line-height: 1.6em;
  max-width: 80%;
  margin: 2.5em 0 3em 0;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.7em;
  max-width: 80%;
  margin: 2.5em 0;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.6em; }

.page--text {
  margin: 0 0 1em 0; }
  .page--text.page--text--no-column .col-left {
    margin: 0 0 40px 0; }

/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
.header {
  *zoom: 1;
  height: auto;
  position: relative;
  background-color: #4b1e46;
  width: 700px;
  height: 100px;
  margin: 30px auto;
  z-index: 10;
  width: 920px;
  height: 120px;
  margin: 40px auto; }
  .header:before, .header:after {
    display: table;
    content: ""; }
  .header:after {
    clear: both; }
  .header:before {
    display: block;
    content: "";
    height: 100%;
    width: 1px;
    background-color: rgba(243, 243, 243, 0.2);
    position: absolute;
    top: 0;
    left: 50%; }
  .header:after {
    display: block;
    content: "";
    height: 40%;
    width: 1px;
    background-color: #f3f3f3;
    position: absolute;
    top: 30%;
    left: 50%; }

/***** LOGO *****/
.header__logo {
  z-index: 20;
  display: inline-block;
  width: auto;
  height: 100px;
  background-color: transparent;
  height: 120px; }
  .header__logo .wrapper--header {
    display: inline-block;
    width: auto;
    height: 100%;
    padding: 35px 20px;
    padding: 38px 30px; }

.logo {
  display: block;
  text-indent: -9999px;
  position: relative;
  z-index: 110;
  width: 210px;
  height: 30px;
  background: transparent url("/images/logo-arcole.png") 0 0 no-repeat;
  background-size: 210px 30px;
  width: 240px;
  height: 34px;
  background-size: 240px 34px;
  width: 320px;
  height: 46px;
  background-size: 320px 46px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    .logo {
      background-image: url("/images/logo-arcole@2x.png");
      background-size: 210px 30px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    .logo {
      background-image: url("/images/logo-arcole@2x.png");
      background-size: 240px 34px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    .logo {
      background-image: url("/images/logo-arcole@2x.png");
      background-size: 320px 46px; } }

/***** HEADER__MENU *****/
.header__menu {
  z-index: 10;
  max-width: 420px;
  float: right;
  max-width: 460px;
  float: right; }
  .header__menu .wrapper--header {
    width: auto; }

/***** NAV (Commun) *****/
.nav {
  width: auto;
  float: right;
  text-align: right; }
  .nav ul li {
    display: inline-block; }
    .nav ul li a {
      display: inline-block;
      position: relative;
      text-align: center;
      font-size: 16px;
      font-size: 1.6rem;
      color: #baa264;
      transition: color 0.2s ease-in-out; }

/***** NAV ALT *****/
.nav-alt {
  margin-bottom: 3vh;
  margin-bottom: 10px;
  margin-bottom: 14px; }
  .nav-alt ul li {
    position: relative;
    margin-left: 3px;
    margin-left: 5px; }
    .nav-alt ul li:before {
      display: block;
      content: "";
      width: 1px;
      height: 16px;
      background-color: #baa264;
      background-color: rgba(186, 162, 100, 0.4);
      position: absolute;
      top: 2px;
      left: -4px; }
    .nav-alt ul li:first-of-type {
      margin: 0; }
      .nav-alt ul li:first-of-type:before {
        display: none; }
    .nav-alt ul li:before {
      left: -5px; }
    .nav-alt ul li a {
      padding: 0 20px;
      border: 1px solid transparent;
      line-height: 2em;
      color: #baa264;
      transition: color 0.3s ease-in-out;
      padding: 0 4px;
      line-height: 1.8em;
      text-transform: uppercase;
      font-size: 10px;
      font-size: 1rem;
      color: #baa264; }
      .nav-alt ul li a:after {
        content: '';
        position: absolute;
        background: #baa264;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        transform: scale3d(0, 0.1, 1);
        transition: transform 0.3s;
        transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95); }
      .no-touch .nav-alt ul li a:hover,
      .no-touchevents .nav-alt ul li a:hover,
      .no-touch .nav-alt ul li a:focus,
      .no-touchevents .nav-alt ul li a:focus,
      .touch .nav-alt ul li a:active,
      .touchevents .nav-alt ul li a:active, .nav-alt ul li a.current {
        color: #a38948;
        color: #cab787; }
        .no-touch .nav-alt ul li a:hover:after,
        .no-touchevents .nav-alt ul li a:hover:after,
        .no-touch .nav-alt ul li a:focus:after,
        .no-touchevents .nav-alt ul li a:focus:after,
        .touch .nav-alt ul li a:active:after,
        .touchevents .nav-alt ul li a:active:after, .nav-alt ul li a.current:after {
          transform: scale3d(1, 1, 1);
          transform: scale3d(0.88, 0.88, 0.88); }

/***** NAV MAIN *****/
.nav-main ul li {
  margin-left: 1px;
  float: left; }
  .nav-main ul li:first-of-type {
    margin: 0; }
  .nav-main ul li a {
    padding: 0 15px;
    border: 1px solid transparent;
    line-height: 2.3em;
    font-family: "gotham_mediumregular", sans-serif;
    text-transform: uppercase;
    color: #4b1e46;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    padding: 0 10px;
    line-height: 2.6em;
    font-size: 10px;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    color: rgba(255, 255, 255, 0.9);
    padding: 0 12px;
    font-size: 11px;
    font-size: 1.1rem; }
    .nav-main ul li a:before, .nav-main ul li a:after {
      content: '';
      position: absolute;
      background-color: #4b1e46;
      left: 0;
      width: 100%;
      height: 1px;
      transform: scale3d(0, 0.1, 1);
      transition: transform 0.3s, background-color 0.3s;
      transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.55);
      transform: scale3d(1, 1, 1); }
    .nav-main ul li a:before {
      top: 0; }
    .nav-main ul li a:after {
      bottom: 0; }
    .nav-main ul li a span {
      position: absolute;
      display: block;
      min-height: 100%;
      width: 100%;
      top: 0;
      left: 0; }
      .nav-main ul li a span:before, .nav-main ul li a span:after {
        content: '';
        position: absolute;
        background: #4b1e46;
        top: 0;
        width: 1px;
        height: 100%;
        transform: scale3d(0.1, 0, 1);
        transition: transform 0.3s, background-color 0.3s;
        transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.6);
        transform: scale3d(1, 1, 1); }
      .nav-main ul li a span:before {
        left: 0; }
      .nav-main ul li a span:after {
        right: 0; }
    .no-touch .nav-main ul li a:hover,
    .no-touchevents .nav-main ul li a:hover,
    .no-touch .nav-main ul li a:focus,
    .no-touchevents .nav-main ul li a:focus,
    .touch .nav-main ul li a:active,
    .touchevents .nav-main ul li a:active, .nav-main ul li a.current {
      color: #4b1e46;
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff; }
      .no-touch .nav-main ul li a:hover:before, .no-touch .nav-main ul li a:hover:after,
      .no-touchevents .nav-main ul li a:hover:before,
      .no-touchevents .nav-main ul li a:hover:after,
      .no-touch .nav-main ul li a:focus:before,
      .no-touch .nav-main ul li a:focus:after,
      .no-touchevents .nav-main ul li a:focus:before,
      .no-touchevents .nav-main ul li a:focus:after,
      .touch .nav-main ul li a:active:before,
      .touch .nav-main ul li a:active:after,
      .touchevents .nav-main ul li a:active:before,
      .touchevents .nav-main ul li a:active:after, .nav-main ul li a.current:before, .nav-main ul li a.current:after {
        transform: scale3d(1, 1, 1);
        background-color: #fff; }
      .no-touch .nav-main ul li a:hover span:before, .no-touch .nav-main ul li a:hover span:after,
      .no-touchevents .nav-main ul li a:hover span:before,
      .no-touchevents .nav-main ul li a:hover span:after,
      .no-touch .nav-main ul li a:focus span:before,
      .no-touch .nav-main ul li a:focus span:after,
      .no-touchevents .nav-main ul li a:focus span:before,
      .no-touchevents .nav-main ul li a:focus span:after,
      .touch .nav-main ul li a:active span:before,
      .touch .nav-main ul li a:active span:after,
      .touchevents .nav-main ul li a:active span:before,
      .touchevents .nav-main ul li a:active span:after, .nav-main ul li a.current span:before, .nav-main ul li a.current span:after {
        transform: scale3d(1, 1, 1);
        background-color: #fff; }

/* 
########    #######    ######     ######    ##         ########   
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##         ##         ##         ##         
   ##      ##     ##  ##   ####  ##   ####  ##         ######     
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##       #######    ######     ######    ########   ########   
*/
.nav-toggle {
  position: fixed;
  width: 60px;
  height: 64px;
  top: 0px;
  right: 0;
  z-index: 130;
  display: none; }

.lines-button {
  position: absolute;
  height: auto;
  width: auto;
  right: 20px;
  top: 20px;
  z-index: 101;
  display: inline-block;
  transition: .3s;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0;
  display: none; }
  .no-touch .nav-toggle:hover .lines-button:not(.close) .lines,
  .no-touch .nav-toggle:hover .lines-button .lines:before,
  .no-touch .nav-toggle:hover .lines-button .lines:after {
    background: rgba(186, 162, 100, 0.8); }

.lines {
  display: inline-block;
  width: 18px;
  height: 3px;
  background: #baa264;
  transition: 0.2s ease-in-out;
  width: 20px;
  position: relative; }
  .lines:before, .lines:after {
    display: inline-block;
    width: 18px;
    height: 3px;
    background: #baa264;
    transition: 0.2s ease-in-out;
    width: 20px;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: center center; }
  .lines:before {
    top: 6px; }
  .lines:after {
    top: -6px; }

.lines-button.close .lines {
  background: transparent; }
  .lines-button.close .lines:before, .lines-button.close .lines:after {
    transform-origin: 50% 50%;
    top: 0;
    width: 20px; }
  .lines-button.close .lines:before {
    transform: rotate3d(0, 0, 1, 45deg); }
  .lines-button.close .lines:after {
    transform: rotate3d(0, 0, 1, -45deg); }

/* 
##     ##     ###     ####  ##    ##   
###   ###    ## ##     ##   ###   ##   
#### ####   ##   ##    ##   ####  ##   
## ### ##  ##     ##   ##   ## ## ##   
##     ##  #########   ##   ##  ####   
##     ##  ##     ##   ##   ##   ###   
##     ##  ##     ##  ####  ##    ##   
*/
/***** HOME *****/
.block--image--home-louvre:before {
  display: block;
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: transparent url("/images/home-louvre.jpg") top center no-repeat;
  background-size: cover;
  height: 100%;
  z-index: 0; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    .block--image--home-louvre:before {
      background-image: url("/images/home-louvre@2x.jpg"); } }

.block--image--home-louvre .block--inner {
  height: 50%;
  width: 100%;
  top: 50%; }

.home--intro-img {
  width: 100%;
  height: auto; }

.block--inner--intro {
  width: 66.66667% !important;
  left: auto;
  right: 0;
  z-index: 10; }

.home--intro-text {
  font-family: "playfair_displayitalic", serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #444;
  line-height: 1.5em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.8em; }

.block--inner--half {
  height: 50%; }
  .block--inner--half.block--inner--half-top {
    top: 0; }
  .block--inner--half.block--inner--half-bottom {
    top: 50%; }

/*** .block-link ***/
.block-link {
  display: block;
  height: 100%;
  *zoom: 1; }
  .block-link:before, .block-link:after {
    display: table;
    content: ""; }
  .block-link:after {
    clear: both; }
  .block-link .block-link__text {
    display: block;
    line-height: 1.6em;
    text-transform: uppercase;
    margin-right: 4px;
    margin: auto;
    position: relative;
    display: block;
    width: 75%;
    width: calc(75% + 1px);
    height: 75%;
    height: calc(75% + 1px);
    border: 1px solid; }
    .block--inner--color-1 .block-link .block-link__text {
      color: #fff; }
    .block--inner--color-white .block-link .block-link__text {
      color: #baa264; }
    .block-link .block-link__text:before, .block-link .block-link__text:after {
      content: '';
      width: 100%;
      width: calc(100% + 2px);
      height: 1px;
      position: absolute;
      left: -1px;
      transform: scale3d(0, 0.1, 1);
      transition: transform 0.3s;
      transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95); }
      .block--inner--color-1 .block-link .block-link__text:before, .block--inner--color-1 .block-link .block-link__text:after {
        background-color: #fff; }
      .block--inner--color-white .block-link .block-link__text:before, .block--inner--color-white .block-link .block-link__text:after {
        background-color: #baa264; }
    .block-link .block-link__text:before {
      top: -1px; }
    .block-link .block-link__text:after {
      bottom: -1px; }
    .block-link .block-link__text span.frame-hover {
      position: absolute;
      display: block;
      min-height: 100%;
      width: 100%;
      top: 0;
      left: 0; }
      .block-link .block-link__text span.frame-hover:before, .block-link .block-link__text span.frame-hover:after {
        content: '';
        width: 1px;
        height: 100%;
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        transform: scale3d(0.1, 0, 1);
        transition: transform 0.3s;
        transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95); }
        .block--inner--color-1 .block-link .block-link__text span.frame-hover:before, .block--inner--color-1 .block-link .block-link__text span.frame-hover:after {
          background-color: #fff; }
        .block--inner--color-white .block-link .block-link__text span.frame-hover:before, .block--inner--color-white .block-link .block-link__text span.frame-hover:after {
          background-color: #baa264; }
      .block-link .block-link__text span.frame-hover:before {
        left: -1px; }
      .block-link .block-link__text span.frame-hover:after {
        right: -1px; }
    .block-link .block-link__text span.text-content {
      position: absolute;
      line-height: 1.25em;
      font-size: 22px;
      font-size: 2.2rem;
      line-height: 1.3em;
      font-size: 15px;
      font-size: 1.5rem; }
      .block-link .block-link__text span.text-content.text-content--top {
        top: 20px;
        left: 20px;
        padding-right: 20px;
        text-align: left;
        top: 15px;
        left: 15px; }
      .block-link .block-link__text span.text-content.text-content--bottom {
        bottom: 20px;
        right: 20px;
        padding-left: 20px;
        text-align: right;
        bottom: 15px;
        right: 15px; }
  .block-link .block-link__btn {
    display: inline-block;
    border: 1px solid;
    width: 16px;
    height: 16px;
    position: relative;
    display: block;
    width: 25%;
    height: 25%;
    margin: 75% 0 0 -1px;
    border: 1px solid;
    float: right; }
    .block-link .block-link__btn:before {
      display: inline-block;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      font-size: 11px;
      font-size: 1.1rem;
      text-align: center;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
      font-size: 30px;
      font-size: 3rem;
      line-height: 2.15em;
      font-size: 21px;
      font-size: 2.1rem;
      line-height: 1.88em; }
  .no-touch .block-link:hover,
  .no-touchevents .block-link:hover,
  .no-touch .block-link:focus,
  .no-touchevents .block-link:focus,
  .touch .block-link:active,
  .touchevents .block-link:active {
    cursor: pointer; }
    .no-touch .block-link:hover .block-link__text:before, .no-touch .block-link:hover .block-link__text:after,
    .no-touchevents .block-link:hover .block-link__text:before,
    .no-touchevents .block-link:hover .block-link__text:after,
    .no-touch .block-link:focus .block-link__text:before,
    .no-touch .block-link:focus .block-link__text:after,
    .no-touchevents .block-link:focus .block-link__text:before,
    .no-touchevents .block-link:focus .block-link__text:after,
    .touch .block-link:active .block-link__text:before,
    .touch .block-link:active .block-link__text:after,
    .touchevents .block-link:active .block-link__text:before,
    .touchevents .block-link:active .block-link__text:after {
      transform: scale3d(1, 1, 1); }
    .no-touch .block-link:hover .block-link__text span.frame-hover:before,
    .no-touch .block-link:hover .block-link__text span.frame-hover:after,
    .no-touchevents .block-link:hover .block-link__text span.frame-hover:before,
    .no-touchevents .block-link:hover .block-link__text span.frame-hover:after,
    .no-touch .block-link:focus .block-link__text span.frame-hover:before,
    .no-touch .block-link:focus .block-link__text span.frame-hover:after,
    .no-touchevents .block-link:focus .block-link__text span.frame-hover:before,
    .no-touchevents .block-link:focus .block-link__text span.frame-hover:after,
    .touch .block-link:active .block-link__text span.frame-hover:before,
    .touch .block-link:active .block-link__text span.frame-hover:after,
    .touchevents .block-link:active .block-link__text span.frame-hover:before,
    .touchevents .block-link:active .block-link__text span.frame-hover:after {
      transform: scale3d(1, 1, 1); }
    .no-touch .block-link:hover .block-link__btn:before,
    .no-touchevents .block-link:hover .block-link__btn:before,
    .no-touch .block-link:focus .block-link__btn:before,
    .no-touchevents .block-link:focus .block-link__btn:before,
    .touch .block-link:active .block-link__btn:before,
    .touchevents .block-link:active .block-link__btn:before {
      color: #4b1e46;
      transform: rotate(360deg); }
    .no-touch .block-link:hover.block-link--dark .block-link__btn,
    .no-touchevents .block-link:hover.block-link--dark .block-link__btn,
    .no-touch .block-link:focus.block-link--dark .block-link__btn,
    .no-touchevents .block-link:focus.block-link--dark .block-link__btn,
    .touch .block-link:active.block-link--dark .block-link__btn,
    .touchevents .block-link:active.block-link--dark .block-link__btn {
      background-color: #fff; }
      .no-touch .block-link:hover.block-link--dark .block-link__btn:before,
      .no-touchevents .block-link:hover.block-link--dark .block-link__btn:before,
      .no-touch .block-link:focus.block-link--dark .block-link__btn:before,
      .no-touchevents .block-link:focus.block-link--dark .block-link__btn:before,
      .touch .block-link:active.block-link--dark .block-link__btn:before,
      .touchevents .block-link:active.block-link--dark .block-link__btn:before {
        color: #4b1e46; }
    .no-touch .block-link:hover.block-link--light .block-link__btn,
    .no-touchevents .block-link:hover.block-link--light .block-link__btn,
    .no-touch .block-link:focus.block-link--light .block-link__btn,
    .no-touchevents .block-link:focus.block-link--light .block-link__btn,
    .touch .block-link:active.block-link--light .block-link__btn,
    .touchevents .block-link:active.block-link--light .block-link__btn {
      background-color: #baa264; }
      .no-touch .block-link:hover.block-link--light .block-link__btn:before,
      .no-touchevents .block-link:hover.block-link--light .block-link__btn:before,
      .no-touch .block-link:focus.block-link--light .block-link__btn:before,
      .no-touchevents .block-link:focus.block-link--light .block-link__btn:before,
      .touch .block-link:active.block-link--light .block-link__btn:before,
      .touchevents .block-link:active.block-link--light .block-link__btn:before {
        color: #fff; }
  .block-link.block-link--left .block-link__text {
    float: left; }
  .block-link.block-link--left .block-link__btn {
    float: right;
    margin: 75% 0 0 -1px; }
  .block-link.block-link--right .block-link__text {
    float: right; }
  .block-link.block-link--right .block-link__btn {
    float: left;
    margin: 75% -1px 0 0; }
  .block-link.block-link--light {
    color: #baa264;
    border-color: #baa264; }
    .block-link.block-link--light .block-link__text {
      border-color: rgba(186, 162, 100, 0.35); }
    .block-link.block-link--light .block-link__btn {
      border-color: #baa264;
      background-color: rgba(186, 162, 100, 0.1); }
      .block-link.block-link--light .block-link__btn:before {
        color: #baa264; }
  .block-link.block-link--dark {
    color: #fff;
    border-color: #fff; }
    .block-link.block-link--dark .block-link__text {
      border-color: rgba(255, 255, 255, 0.3); }
    .block-link.block-link--dark .block-link__btn {
      border-color: #fff;
      background-color: rgba(255, 255, 255, 0.1); }
      .block-link.block-link--dark .block-link__btn:before {
        color: #fff; }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.block--image--home-hotel {
  background: transparent url("/images/home-hotel.jpg") 0 0 no-repeat;
  background-size: cover; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    .block--image--home-hotel {
      background-image: url("/images/home-hotel@2x.jpg");
      background-size: cover; } }

.col--actu-home:nth-of-type(2n) .block--inner {
  background-color: #fff; }

.actu-home .actu--date {
  position: relative;
  margin: 0 0 0.5em 0;
  font-family: "playfair_displayregular", serif;
  font-size: 24px;
  font-size: 2.4rem;
  color: #4b1e46;
  margin: 0 0 0.6em 0;
  font-size: 26px;
  font-size: 2.6rem;
  font-size: 20px;
  font-size: 2rem; }
  .actu-home .actu--date:after {
    display: block;
    content: "";
    width: 60px;
    height: 1px;
    background-color: #4b1e46;
    position: absolute;
    top: 110%;
    left: 0;
    top: 115%; }

.actu-home .actu--text {
  margin: 0 0 1em 0;
  color: #606060;
  font-size: 12px;
  font-size: 1.2rem; }

.actu-home .actu--link {
  color: #772f6f;
  font-size: 12px;
  font-size: 1.2rem; }
  .no-touch .actu-home .actu--link:hover,
  .no-touchevents .actu-home .actu--link:hover,
  .no-touch .actu-home .actu--link:focus,
  .no-touchevents .actu-home .actu--link:focus,
  .touch .actu-home .actu--link:active,
  .touchevents .actu-home .actu--link:active {
    color: #a38948; }

/***** SOCIÉTÉ *****/
.block--image--societe {
  background: transparent url("/images/illu-societe.jpg") 0 0 no-repeat; }

/***** VALEURS *****/
.row--valeurs {
  min-height: 460px; }

.valeur--content {
  *zoom: 1;
  margin-top: -50%;
  width: 100%;
  position: absolute;
  z-index: 0; }
  .valeur--content:before, .valeur--content:after {
    display: table;
    content: ""; }
  .valeur--content:after {
    clear: both; }

.block--inner--valeur-item {
  padding: 20px 25px; }
  .block--inner--valeur-item.block--inner--color-1 .title-lev2, .block--inner--valeur-item.block--inner--color-3 .title-lev2 {
    color: #fff; }
  .block--inner--valeur-item .title-lev2 {
    max-width: 80%;
    max-width: 75%;
    max-width: 95%; }

.valeur--number {
  position: relative;
  margin: 0 0 0.4em 0;
  font-family: "gotham_mediumregular", sans-serif;
  font-size: 28px;
  font-size: 2.8rem;
  color: #4b1e46;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 0.45em 0;
  font-size: 30px;
  font-size: 3rem;
  font-size: 20px;
  font-size: 2rem; }
  .block--inner--color-1 .valeur--number,
  .block--inner--color-3 .valeur--number {
    color: #fff; }
  .valeur--number:after {
    display: block;
    content: "";
    width: 60px;
    height: 1px;
    background-color: #4b1e46;
    position: absolute;
    top: 110%;
    left: 0;
    top: 115%; }
    .block--inner--color-1 .valeur--number:after,
    .block--inner--color-3 .valeur--number:after {
      background-color: #fff; }

.valeur--text {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.1em; }
  .block--inner--color-1 .valeur--text,
  .block--inner--color-3 .valeur--text {
    color: #fff;
    color: rgba(255, 255, 255, 0.75); }

/***** CONTACT *****/
.contact-icon--container {
  line-height: 1.6em; }

.contact-icon {
  display: inline-block;
  margin: 0 6px 0 0; }
  .contact-icon:before {
    display: block;
    line-height: 2em;
    font-size: 11px;
    font-size: 1.1rem;
    color: #444; }

.contact-cdaf {
  display: inline-block;
  position: relative;
  padding: 0 0 0 78px;
  margin: 1.6em 0 1.4em 0;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.35em;
  margin-top: 2.4em; }
  .contact-cdaf:before {
    display: block;
    content: "";
    width: 68px;
    height: 29px;
    position: absolute;
    top: 2px;
    left: 0;
    background: transparent url("/images/cdaf2.png") 0 0 no-repeat;
    background-size: 68px 58px;
    transition: background-position 0.2s ease-in-out; }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
      .contact-cdaf:before {
        background-image: url("/images/cdaf2@2x.png");
        background-size: 68px 58px; } }
  .no-touch .contact-cdaf:hover,
  .no-touchevents .contact-cdaf:hover,
  .no-touch .contact-cdaf:focus,
  .no-touchevents .contact-cdaf:focus,
  .touch .contact-cdaf:active,
  .touchevents .contact-cdaf:active {
    cursor: pointer; }
    .no-touch .contact-cdaf:hover:before,
    .no-touchevents .contact-cdaf:hover:before,
    .no-touch .contact-cdaf:focus:before,
    .no-touchevents .contact-cdaf:focus:before,
    .touch .contact-cdaf:active:before,
    .touchevents .contact-cdaf:active:before {
      background-position: 0 -30px; }

.contact-form li:not(:last-of-type) {
  position: relative;
  margin: 0 0 8px 0;
  padding: 15px 10px;
  background-color: #f3f3f3;
  border: 2px solid #f3f3f3;
  color: #4b1e46;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  width: 350px; }
  .contact-form li:not(:last-of-type).input-active {
    background-color: transparent;
    border-color: #b29752; }
    .contact-form li:not(:last-of-type).input-active label {
      font-size: 9px;
      font-size: 0.9rem;
      top: 5px;
      color: #917b41; }
  .contact-form li:not(:last-of-type).error {
    border-color: #FF4242; }

.contact-form input,
.contact-form textarea {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: none;
  background-color: transparent; }

.contact-form textarea {
  height: 120px; }

.contact-form label {
  display: block;
  padding: 0 10px;
  line-height: 1em;
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  font-size: 13px;
  font-size: 1.3rem;
  transition: top 0.2s ease-in-out, color 0.2s ease-in-out, font-size 0.2s ease-in-out; }

.contact-form button.submit {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  height: 50px;
  line-height: 51px;
  width: 100%;
  padding: 0 40px;
  border: none;
  background-color: #4b1e46;
  vertical-align: middle;
  z-index: 1;
  overflow: hidden;
  font-family: "gotham_bookregular", sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: background-color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  width: auto;
  margin: 10px 0 0 0; }
  .contact-form button.submit:after,
  .contact-form button.submit span {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 0.3s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1); }
  .contact-form button.submit:after {
    padding: 0 20px;
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translate3d(0, 40%, 0); }
  .contact-form button.submit span {
    vertical-align: middle; }
  .no-touch .contact-form button.submit:hover, .no-touch .contact-form button.submit:focus {
    background-color: #baa264; }
    .no-touch .contact-form button.submit:hover:after, .no-touch .contact-form button.submit:focus:after {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .no-touch .contact-form button.submit:hover span, .no-touch .contact-form button.submit:focus span {
      opacity: 0;
      transform: translate3d(0, -25%, 0); }

/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/
.footer {
  position: relative;
  z-index: 10;
  background-color: #3f2e3d;
  height: 110px;
  height: 80px; }
  .footer p, .footer a {
    line-height: 1.4em;
    font-family: "gotham_bookregular", sans-serif;
    font-size: 12px;
    font-size: 1.2rem;
    color: #fff;
    color: rgba(255, 255, 255, 0.75);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0; }
    .footer p.footer--left, .footer a.footer--left {
      width: auto;
      float: left; }
    .footer p.footer--right, .footer a.footer--right {
      color: rgba(255, 255, 255, 0.55);
      width: auto;
      float: right; }
  .footer span.footer-strong {
    font-family: "gotham_mediumregular", sans-serif;
    text-transform: uppercase; }
  .footer a {
    transition: color 0.2s ease-in-out; }
    .no-touch .footer a:hover,
    .no-touchevents .footer a:hover,
    .no-touch .footer a:focus,
    .no-touchevents .footer a:focus,
    .touch .footer a:active,
    .touchevents .footer a:active {
      color: #fff; }

#__bs_notify__ {
  right: 50% !important;
  width: 200px;
  margin-right: -100px !important;
  border-radius: 0 !important;
  background: rgba(255, 0, 0, 0.8) !important;
  font-size: 14px !important; }
